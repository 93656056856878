import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import logo from '../images/logo.png'

const ThankYou = () => (
  <Layout>
    <div className="body thank-you">
      <div id="wrapperInner">
        <div className="logo">
          <img src={logo} alt=""></img>
        </div>
        <div className="text-cnt">
          <h1>Thank you</h1>
          <p>Thank you for your submission!</p>
          <Link to="/" className="btn">Go back to the homepage</Link>
        </div>
      </div>
    </div>
    <div id="bg"></div>
  </Layout>
)

export default ThankYou
